import React from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import above from '@/utils/above'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  ${above('xg')`
    align-items: center;
  `}
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
`

export const Desktop = styled.div`
  display: none;
  ${above('xg')`
    display: block;
  `}
`

export const Mobile = styled.div`
  display: block;
  ${above('xg')`
    display: none;
  `}
`
const NavLinkText = styled(UI.SmallText)`
  ${p =>
    p.main &&
    css`
      font-family: ${p.theme.type.fonts.headline};
      font-weight: 500;
      ${getTypeStyle('subtitle')};
    `}
`

export const StyledLink = styled(UI.Link)`
  display: block;
  padding: 0.375rem 0;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }
  &.active {
    ${NavLinkText} {
      color: ${p => p.theme.colors.gray};
    }
    
  }
  ${above('xg')`
    padding: 2rem 1rem;
  `}
  ${p =>
    p.main &&
    `
    padding: 0.5rem 0 !important;
  `}
  ${p =>
    p.mobile &&
    `
    padding: 0.375rem 0 !important;
  `}
`

export const LogoLink = styled(StyledLink)`
  padding: 1.75rem 0;
  ${above('xg')`
    margin-left: -1rem;
  `}
`

export const NavLink = ({ children, main, ...props }) => (
  <StyledLink main={main} {...props}>
    <NavLinkText main={main}>{children}</NavLinkText>
  </StyledLink>
)

export const Toggle = styled.button`
  position: relative;
  color: currentColor;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-self: stretch;
  margin-right: -1rem;
  padding: 1.5rem 1rem;
`

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100%;
  transition: 0.2s opacity;
  background: ${p => p.theme.colors.maastricht}F9;
  color: ${p => p.theme.colors.white};
  opacity: ${p => (p.open ? 1 : 0)};
  pointer-events: ${p => (p.open ? 'all' : 'none')};
  z-index: 100;
`

export const MobileContainer = styled(UI.Container)`
  flex: 1;
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin: 3rem 0;
  background: ${p => p.theme.colors.slate};
`

export const Label = styled(UI.Label)`
  color: ${p => p.theme.colors.slate};
  text-transform: uppercase;
  font-weight: 500;
`

export const SocialBar = styled(UI.SocialBar)`
  margin-bottom: 1.5rem;
`

export const LinkList = styled.div`
  margin: 1.125rem 0;
`

export const LegalList = styled.div`
  margin: 0.5rem 0 2.5rem;
`
export const BlockBody = createGlobalStyle`
${p =>
  p.open &&
  css`
    html,
    body {
      overflow: hidden;
    }
  `}
 
`
