import React from 'react'
import * as UI from '@/components/UI'
import {
  Wrapper,
  Desktop,
  Mobile,
  LogoLink,
  NavLink,
  Toggle,
  Label,
  MenuWrapper,
  MobileContainer,
  Separator,
  LinkList,
  LegalList,
  SocialBar,
  BlockBody,
} from './NavBar.css'

const MobileMenu = ({
  links,
  information,
  legal,
  informationTitle,
  legalTitle,
  painfinderTitle,
  painfinder,
  services,
  open,
  setOpen,
  ...content
}) => (
  <UI.Portal>
    <BlockBody open={open} />
    <MenuWrapper open={open}>
      <MobileContainer>
        <div>
          <Wrapper>
            <LogoLink to='/'>
              <Mobile>
                <UI.Logo size={12} />
              </Mobile>
              <Desktop>
                <UI.Logo />
              </Desktop>
            </LogoLink>
            <Toggle onClick={() => setOpen(false)}>
              <UI.Icon.Close />
            </Toggle>
          </Wrapper>
          <LinkList>
            <Label>{services}</Label>
            {links?.map(link => (
              <NavLink key={link.children} main {...link} />
            ))}
          </LinkList>
          <LinkList>
            <Label>{informationTitle}</Label>
            {information?.map(link => (
              <NavLink main {...link} />
            ))}
          </LinkList>
          <LinkList>
            <Label>{painfinderTitle}</Label>
            <NavLink main to='/pain-finder'>
              {painfinder}
            </NavLink>
          </LinkList>
          <Separator />
          <Label>{legalTitle}</Label>
          <LegalList>
            {legal?.map(link => (
              <NavLink mobile {...link} />
            ))}
          </LegalList>
        </div>
        <SocialBar {...content} />
      </MobileContainer>
    </MenuWrapper>
  </UI.Portal>
)

export default MobileMenu
