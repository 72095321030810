import React from 'react'
import {
  ClientListWrapper,
  ClientListContainer,
  ClientListGrid,
  ClientCell,
} from './Footer.css'

const ClientList = ({ clients = [] }) => (
  <ClientListWrapper>
    <ClientListContainer>
      <ClientListGrid vertical-gutter>
        {clients.map(({ url, alt, image }) => (
          <ClientCell as='a' href={url?.raw?.url} target='_blank'>
            <img src={image.url} alt={alt} />
          </ClientCell>
        ))}
      </ClientListGrid>
    </ClientListContainer>
  </ClientListWrapper>
)

export default ClientList
