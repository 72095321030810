import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import flattenEdges from '@/utils/flattenEdges'
import { useContext } from '@/store'
import * as UI from '@/components/UI'
import {
  Wrapper,
  Flex,
  Desktop,
  Mobile,
  LogoLink,
  NavLink,
  Toggle,
} from './NavBar.css'
import MobileMenu from './MobileMenu'

const query = graphql`
  query NavBar {
    data: allPrismicNavbar {
      edges {
        node {
          lang
          data {
            services: services_title
            painfinderTitle: painfinder_title
            painfinder
            informationTitle: information_title
            information {
              children: title
              to: url
            }
            legalTitle: legal_title
            legal {
              children: title
              to: url
            }
            links {
              children: title
              to: url
            }
            facebook {
              url
            }
            twitter {
              url
            }
            instagram {
              url
            }
            linkedin {
              url
            }
            youtube {
              url
            }
          }
        }
      }
    }
  }
`

const mapData = (x, l) => flattenEdges(x).find(y => y.lang === l)?.data

const NavBar = () => {
  const [open, setOpen] = useState(false)
  const { data } = useStaticQuery(query)
  const { locale } = useContext()
  const content = mapData(data, locale)
  return (
    <nav>
      <UI.Container>
        <Wrapper>
          <LogoLink to='/'>
            <Mobile>
              <UI.Logo size={12} />
            </Mobile>
            <Desktop>
              <UI.Logo />
            </Desktop>
          </LogoLink>
          <Desktop>
            <Flex>
              {content?.links?.map(link => (
                <NavLink key={link.children} {...link} />
              ))}
              {content?.information?.map(link => (
                <NavLink {...link} />
              ))}
            </Flex>
          </Desktop>
          {content?.painfinder && (
            <Desktop>
              <UI.Button appearance='ghost' hasHoverRed to='/pain-finder'>
                {content.painfinder}
              </UI.Button>
            </Desktop>
          )}
          <Mobile>
            <Toggle onClick={() => setOpen(!open)}>
              {open ? <UI.Icon.Close /> : <UI.Icon.Burguer />}
            </Toggle>
          </Mobile>
        </Wrapper>
      </UI.Container>
      <MobileMenu open={open} setOpen={setOpen} {...content} />
    </nav>
  )
}

export default NavBar
