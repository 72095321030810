import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import * as UI from '@/components/UI'

const Subscriber = ({
  newsletterTitle,
  newsletterLabel,
  successMessage,
  duplicatedMessage,
  deletedMessage,
  genericError,
}) => {
  const [email, setEmail] = React.useState('')
  // 'initial' | 'success' | 'duplicated' | 'deleted' | 'error'
  const [status, setStatus] = React.useState('initial')

  const isSuccess = status === 'success'

  const onChange = e => setEmail(e.currentTarget.value)

  const getErrorMessage = currStatus => {
    switch (currStatus) {
      case 'error':
        return genericError
      case 'deleted':
        return deletedMessage
      case 'duplicated':
        return duplicatedMessage
      default:
        return ''
    }
  }

  const onSubmit = e => {
    e.preventDefault()

    if (!email) {
      return
    }

    addToMailchimp(email, {
      'group[25357][1]': 1,
    })
      .then(({ result, msg }) => {
        if (result === 'success') {
          return setStatus('success')
        }

        // Error handling
        if (msg.includes('already a list member')) {
          return setStatus('duplicated')
        }

        if (msg.includes('permanently deleted')) {
          return setStatus('deleted')
        }

        return setStatus('error')
      })
      .catch(() => {
        setStatus('error')
      })
  }

  return (
    <form onSubmit={onSubmit}>
      <UI.BlogText main>
        {isSuccess ? successMessage : newsletterTitle}
      </UI.BlogText>
      {!isSuccess && (
        <UI.Input
          color='white'
          id='footerNewsletter'
          name='email'
          type='email'
          onChange={onChange}
          value={email}
          icon={<UI.Icon.Arrow />}
          placeholder={newsletterLabel}
          error={getErrorMessage(status)}
        />
      )}
    </form>
  )
}

export default Subscriber
