import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import flattenEdges from '@/utils/flattenEdges'
import { useContext } from '@/store'
import ClientList from './ClientList'
import Main from './Main'
import Bottom from './Bottom'
import { StyledFooter } from './Footer.css'

const query = graphql`
  query Footer {
    data: allPrismicNavbar {
      edges {
        node {
          lang
          data {
            clients {
              alt
              url {
                raw
              }
              image {
                url
              }
            }
            painfinder
            servicesTitle: services_title
            informationTitle: information_title
            information {
              children: title
              to: url
            }
            legalTitle: legal_title
            legal {
              children: title
              to: url
            }
            links {
              children: title
              to: url
            }
            newsletterTitle: newsletter_title
            newsletterLabel: newsletter_label
            successMessage: success_message
            duplicatedMessage: duplicated_message
            emptyMessage: empty_message
            deletedMessage: deleted_message
            genericError: generic_error
            copyright
            facebook {
              url
            }
            twitter {
              url
            }
            instagram {
              url
            }
            linkedin {
              url
            }
            youtube {
              url
            }
          }
        }
      }
    }
  }
`

const mapData = (x, l) => flattenEdges(x).find(y => y.lang === l)?.data

const Footer = ({ clientList }) => {
  const { data } = useStaticQuery(query)
  const { locales, locale } = useContext()
  const content = mapData(data, locale)

  const { painfinder } = content

  return (
    <StyledFooter>
      {clientList && <ClientList {...content} />}
      <Main painfinder={painfinder} {...content} />
      <Bottom locales={locales} locale={locale} {...content} />
    </StyledFooter>
  )
}

export default Footer
