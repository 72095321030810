import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Dark = styled.div`
  background: ${p => p.theme.colors.maastricht};
  color: ${p => p.theme.colors.white};
  position: relative;
`

export const StyledLink = styled(UI.Link)`
  display: block;
  padding: 0.25rem 0;
  transition: opacity ${p => p.theme.transitions.ease()};
  &:hover {
    opacity: 0.8;
  }
`

export const NewsletterWrapper = styled.div`
  margin-left: auto;
  background: ${p => p.theme.colors.white}04;
  padding: 1rem 1.5rem 0.5rem;
  width: 100%;
  ${above('lg')`
    width: auto;
  `}
  input {
    width: 100%;
  }
`

export const BottomWrapper = styled.div`
  padding: 0 0 1.5rem;
  ${above('md')`
    padding: 1rem 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${p => p.theme.colors.slate}20;
  `}
`

export const SocialWrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${above('md')`
    padding: 0;
    flex-direction: row-reverse;
  `}
`

export const StyledGatsbyLink = styled(GatsbyLink)`
  color: ${p => (p.active ? p.theme.colors.white : p.theme.colors.slate)};
  padding: 0.5rem;
  display: flex;
  align-items: center;
`

export const Dot = styled(UI.SmallText)`
  padding: 0.5rem 0;
  &:after {
    content: '·';
  }
  &:last-of-type {
    display: none;
  }
`

export const Label = styled(UI.Label)`
  color: ${p => p.theme.colors.slate};
  text-transform: uppercase;
`

export const LocaleLinkText = styled(UI.Footnote)`
  text-transform: uppercase;
`

export const ClientListWrapper = styled(Dark)`
  padding: 2rem 0 1.5rem;
  ${above('md')`
    padding-top: 2.5rem;
  `}
  ${above('lg')`
    padding-top: 3rem;
  `}
`

export const ClientListContainer = styled(UI.Container)`
  opacity: 0.5;
`

export const ClientListGrid = styled(UI.Grid)`
  justify-content: center;
`

export const SectionList = styled.div`
  padding: 0.5rem 0 2.5rem;
  ${above('lg')`
    padding-bottom: 0rem;
  `}
`

export const MainWrapper = styled(Dark)`
  padding: 3.5rem 0 0;
  ${above('md')`
    padding: 3.5rem 0;
  `}
`

export const LogoWrapper = styled.div`
  padding-bottom: 2.5rem;
`

export const Flex = styled.div`
  display: flex;
`

export const SectionCell = styled.div`
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(6)};
  `}
  ${above('md')`
    width: ${getColumnSize(4)};
  `}
  ${above('lg')`
    width: ${getColumnSize(2.333)};
  `}
  ${above('xg')`
    width: ${getColumnSize(2)};
  `}
`

export const LogoCell = styled.div`
  width: ${getColumnSize(12)};
  ${above('lg')`
    width: ${getColumnSize(1)};
  `}
  ${above('xg')`
    width: ${getColumnSize(2)};
  `}
`

export const NewsletterCell = styled.div`
  display: flex;
  width: ${getColumnSize(12)};
  ${above('lg')`
    width: ${getColumnSize(4)};
  `}
`

export const ClientCell = styled.div`
  width: ${getColumnSize(12 / 3)};
  ${above('sm')`
    width: ${getColumnSize(12 / 4)};
  `}
  ${above('md')`
    width: ${getColumnSize(12 / 5)};
  `}
  ${above('lg')`
    width: ${getColumnSize(12 / 9)};
  `}
`

export const StyledFooter = styled.footer`
  position: relative;
  z-index: 20;
`
