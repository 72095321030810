import React from 'react'
import Subscriber from '@/components/contents/Mailchimp/Subscriber'
import * as UI from '@/components/UI'
import {
  MainWrapper,
  LogoCell,
  LogoWrapper,
  StyledLink,
  NewsletterCell,
  NewsletterWrapper,
  Label,
  SectionCell,
  SectionList,
} from './Footer.css'

const Link = ({ to, children }) => (
  <StyledLink to={to}>
    <UI.SmallText>{children}</UI.SmallText>
  </StyledLink>
)

const Section = ({ hasPainfinder, painfinder, title, list = [] }) => {
  return (
    <SectionCell>
      <Label>{title}</Label>
      <SectionList>
        {list.map(item => (
          <Link key={item.children} {...item} />
        ))}
        {hasPainfinder && <Link to='/pain-finder'>{painfinder}</Link>}
      </SectionList>
    </SectionCell>
  )
}

const Main = ({
  links,
  information,
  legal,
  informationTitle,
  legalTitle,
  servicesTitle,
  newsletterTitle,
  newsletterLabel,
  successMessage,
  duplicatedMessage,
  emptyMessage,
  deletedMessage,
  genericError,
  painfinder,
}) => (
  <MainWrapper>
    <UI.Container>
      <UI.Grid vertical-gutter>
        <LogoCell>
          <LogoWrapper>
            <UI.Logo symbol />
          </LogoWrapper>
        </LogoCell>
        <Section
          title={servicesTitle}
          list={links}
          hasPainfinder
          painfinder={painfinder}
        />
        <Section title={informationTitle} list={information} />
        <Section title={legalTitle} list={legal} />
        <NewsletterCell>
          <NewsletterWrapper>
            <Subscriber
              newsletterTitle={newsletterTitle}
              newsletterLabel={newsletterLabel}
              successMessage={successMessage}
              duplicatedMessage={duplicatedMessage}
              emptyMessage={emptyMessage}
              deletedMessage={deletedMessage}
              genericError={genericError}
            />
          </NewsletterWrapper>
        </NewsletterCell>
      </UI.Grid>
    </UI.Container>
  </MainWrapper>
)

export default Main
