import React, { Fragment } from 'react'
import * as UI from '@/components/UI'
import {
  Dark,
  BottomWrapper,
  StyledGatsbyLink,
  SocialWrapper,
  LocaleLinkText,
  Flex,
  Dot,
} from './Footer.css'

const getLocale = locales => Object.keys(locales)

const getDefaultLocale = locales =>
  Object.values(locales).find(x => x.default).locale

const getLocaleLink = (locale, defaultLocale) => {
  if (locale === defaultLocale) return '/'
  return `/${locale.slice(0, 2)}`
}

const LocaleLink = ({ children, active, ...props }) => (
  <StyledGatsbyLink active={active} {...props}>
    <LocaleLinkText uppercase>{children}</LocaleLinkText>
  </StyledGatsbyLink>
)

const LanguageSwitch = ({ locales, selected }) => {
  const defaultLocale = getDefaultLocale(locales)
  return (
    <>
      {getLocale(locales).map(locale => (
        <Fragment key={locale}>
          <LocaleLink
            to={getLocaleLink(locale, defaultLocale)}
            active={locale === selected}
          >
            {locale.slice(0, 2)}
          </LocaleLink>
          <Dot color='slate' />
        </Fragment>
      ))}
    </>
  )
}

const Bottom = ({ copyright, locales, locale: selected, ...content }) => (
  <Dark>
    <UI.Container>
      <BottomWrapper>
        <SocialWrapper>
          <UI.SocialBar {...content} />
          <Flex>
            <LanguageSwitch locales={locales} selected={selected} />
          </Flex>
        </SocialWrapper>
        <UI.Label color='slate'>{copyright}</UI.Label>
      </BottomWrapper>
    </UI.Container>
  </Dark>
)

export default Bottom
